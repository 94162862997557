import React from "react"
import Content from "../../Content"
import ContentContainer from "../../ContentContainer"
import { getImage } from "gatsby-plugin-image"
import MenuImage from "./MenuImage"
import { useStaticQuery, graphql } from "gatsby"
import ButtonAction from "../../ButtonAction"

//MENU PDF
import menuFile from "../../../data/wantaw-resto.pdf"

const MenuHighlight = () => {
  const menuHighlight = useStaticQuery(graphql`
    {
      menu1: file(relativePath: { eq: "menu-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
          original {
            height
            width
          }
        }
      }
      menu2: file(relativePath: { eq: "menu-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
          original {
            height
            width
          }
        }
      }
      menu3: file(relativePath: { eq: "menu-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
          original {
            height
            width
          }
        }
      }
      menu4: file(relativePath: { eq: "menu-4.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
          original {
            height
            width
          }
        }
      }
    }
  `)

  const menu = [
    {
      id: "1",
      description: "Mushroom Veggie Ramen",
      price: "P230.00",
      image: {
        data: getImage(menuHighlight.menu1),
        width: menuHighlight.menu1
          ? menuHighlight.menu1.childImageSharp.original.width
          : 0,
        height: menuHighlight.menu1
          ? menuHighlight.menu1.childImageSharp.original.height
          : 0,
      },
    },
    {
      id: "2",
      description: "Gyoza Pork",
      price: "P210.00",
      image: {
        data: getImage(menuHighlight.menu2),
        width: menuHighlight.menu2
          ? menuHighlight.menu2.childImageSharp.original.width
          : 0,
        height: menuHighlight.menu2
          ? menuHighlight.menu2.childImageSharp.original.height
          : 0,
      },
    },
    {
      id: "3",
      description: "Wantaw Shakes",
      price: "",
      image: {
        data: getImage(menuHighlight.menu3),
        width: menuHighlight.menu3
          ? menuHighlight.menu3.childImageSharp.original.width
          : 0,
        height: menuHighlight.menu3
          ? menuHighlight.menu3.childImageSharp.original.height
          : 0,
      },
    },
    {
      id: "4",
      description: "Curry Katsu Chicken",
      price: "270.00",
      image: {
        data: getImage(menuHighlight.menu4),
        width: menuHighlight.menu4
          ? menuHighlight.menu4.childImageSharp.original.width
          : 0,
        height: menuHighlight.menu4
          ? menuHighlight.menu4.childImageSharp.original.height
          : 0,
      },
    },
  ]

  const randomizedImage = menu // randomImage(menu)

  return (
    <ContentContainer className="bg-orange1">
      <Content className="flex flex-row flex-wrap mt-12 sm:mt-28 text-orange1">
        <div className="w-full text-center">
          <h1>
            WANTAW <strong>RESTAURANT</strong>
          </h1>
        </div>
        <div className="w-full flex flex-col items-center mt-12 sm:mt-24 mb-20 px-5">
          {randomizedImage.map((m, index) => {
            const portrait = m.image.width < m.image.height
            const previousPortrait =
              index !== 0
                ? randomizedImage[index - 1].image.width <
                  randomizedImage[index - 1].image.height
                : true

            return (
              <MenuImage
                key={`menu${m.id}`}
                index={index}
                portrait={portrait}
                previousPortrait={previousPortrait}
                data={m}
              />
            )
          })}
        </div>
        <div className="w-full flex items-center justify-center -mt-28 md:-mt-32 mb-20 md:mb-28 ">
          <ButtonAction
            to="/menu"
            className="bg-orangeButton rounded-md text-white py-2 px-16"
          >
            See full Menu
          </ButtonAction>
        </div>
      </Content>
    </ContentContainer>
  )
}

// const randomImage = images => {
//   let index = []
//   let random = 0
//   let randomImages = []

//   for (let i = 0; i < 4; i++) {
//     do {
//       random = Math.floor(Math.random() * 4)
//     } while (index.includes(random))
//     index.push(random)
//     randomImages.push(images[random])
//   }

//   return randomImages
// }

export default MenuHighlight
