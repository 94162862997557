import React from "react"
import HeadData from "../data/HeadData"
import HeroBanner from "../components/HeroBanner"
import VisitPacifico from "../components/section/home/VisitPacifico"
import BeingPresent from "../components/section/home/BeingPresent"
import MenuHighlight from "../components/section/home/MenuHighlight"
import Instructors from "../components/section/home/Instructors"
import Footer from "../components/section/Footer"
import Header from "../components/section/Header"

const IndexPage = () => (
  <div className="overflow-x-hidden">
    <HeadData isHome={true} />
    <HeroBanner isHome />
    <Header isHome />
    <div id="about">
      <VisitPacifico />
    </div>
    <BeingPresent />
    <MenuHighlight />
    <div id="rental-rates">
      <Instructors />
    </div>
    <Footer />
  </div>
)

export default IndexPage
