import React from "react"
import Content from "../../Content"
import ContentContainer from "../../ContentContainer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const VisitPacifico = () => {
  const visitPacifico = useStaticQuery(graphql`
    {
      file: file(relativePath: { eq: "visit-pacifico.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      board: file(relativePath: { eq: "board.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = getImage(visitPacifico.file)
  const board = getImage(visitPacifico.board)

  return (
    <ContentContainer className="bg-orange1">
      <Content className="flex flex-col mt-12 2xl:mt-32 mb-14 2xl:mb-48 text-orange1 px-10 2xl:px-0">
        <div className="overflow-y-visible">
          <div className="w-full flex flex-col 2xl:flex-row">
            <div className="w-full sm:w-2/3 2xl:w-1/2 2xl:pr-28 z-50">
              <h1>
                VISIT <strong>PACIFICO</strong>
              </h1>
              <h2 className="mt-6">
                Relaxing haven away from the hustle and bustle of the city
                lifestyle.
              </h2>
              <hr className="w-10/12 mt-4 border-t-2 border-green1" />
              <div className="flex flex-col w-full gap-y-5 mt-12">
                <p>
                  The contrast to the high-energy vibe of General Luna is the
                  little, quiet barangay up North called Pacifico. Just a
                  half-hour drive from the airport or an hour-long commute from
                  the tourist spot General Luna, and you will reach a more
                  laid-back, not as crowded, and a beautiful place here in
                  Siargao. There are two surf spots in Pacifico ( Big Wish and
                  Bamboo Surf break) with local surf instructors who are
                  available for surf lessons & surfboard rentals to help you
                  have the best surf trip you could possibly have.
                </p>
                <p>
                  A community of local businesses like hostels, villa rentals,
                  restaurants, surf shops, etc. are open 7 days a week to cater
                  to your needs while here.{" "}
                </p>
              </div>
            </div>
            <div className="w-full mt-10 2xl:mt-0 2xl:w-1/2 relative z-0">
              <div className="flex justify-center items-center">
                <div className="absolute top-board sm:-top-96 -right-36 2xl:-right-60 w-8/12 2xl:w-full">
                  <GatsbyImage alt="board" image={board} />
                </div>
                <div className="2xl:absolute -top-20 w-11/12 2xl:w-full">
                  <GatsbyImage alt="Visit Pacifico" image={image} />
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </Content>
    </ContentContainer>
  )
}

export default VisitPacifico
