import React from "react"
import ContentContainer from "../../ContentContainer"
import { getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const Instructors = () => {
  const instructor = useStaticQuery(graphql`
    {
      bgImage: file(relativePath: { eq: "rental-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bgImage2: file(relativePath: { eq: "board-rental.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      rental1: file(relativePath: { eq: "rental-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      rental2: file(relativePath: { eq: "rental-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      rental3: file(relativePath: { eq: "rental-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const bgImage = convertToBgImage(getImage(instructor.bgImage))
  const bgImage2 = convertToBgImage(getImage(instructor.bgImage2))
  const rental1 = convertToBgImage(getImage(instructor.rental1))
  const rental2 = convertToBgImage(getImage(instructor.rental2))
  const rental3 = convertToBgImage(getImage(instructor.rental3))

  const InstructorContent = () => {
    return (
      <div className="grid grid-rows-3 grid-cols-12 w-full">
        <div className="row-span-2 col-span-8 md:col-span-8 lg:col-span-8 xl:col-span-5 bg-transparent lg:bg-orangeButton text-white pl-10 xl:pl-20 xl:pr-4 pt-24 pb-40 lg:border-white lg:border-b-4 lg:border-r-4">
          <div className="w-full">
            <div className="inline-block">
              <h1 className="text-4xl border-b border-orange1 lg:border-white pb-7">
                SURF <strong>RENTAL</strong>
              </h1>
            </div>
            <div className="mt-10 flex flex-col gap-y-4 font-karla text-2xl">
              <div className="flex flex-col lg:flex-row gap-x-2">
                <p className="font-normal lg:font-bold">Board + Instructor</p>
                <p className="hidden lg:inline">-</p>
                <p className="text-green1 lg:text-white">P500/hour</p>
              </div>
              <div className="flex flex-col lg:flex-row gap-x-2">
                <p className="font-normal lg:font-bold">Board only</p>
                <p className="hidden lg:inline">-</p>
                <p className="text-green1 lg:text-white">P200/hour</p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden xl:block row-span-2 lg:col-span-4 xl:col-span-5">
          &nbsp;
        </div>
        <div className="row-start-2 col-start-9 md:col-start-9 lg:col-start-10 xl:col-start-11 col-span-4 md:col-span-4 lg:col-span-3 xl:col-span-2 row-span-2 lg:border-white lg:border-t-4 lg:border-l-4 mt-32 lg:mt-20 xl:mt-0">
          <BackgroundImage {...rental3} className="h-full">
            &nbsp;
          </BackgroundImage>
        </div>
        <div className="col-start-1 lg:col-start-3 xl:col-start-6 col-span-4 lg:col-span-3 xl:col-span-2 row-span-1 lg:border-white lg:border-t-4 lg:border-l-4">
          <BackgroundImage {...rental1} className="h-full">
            &nbsp;
          </BackgroundImage>
        </div>
        <div className="col-span-4 md:col-span-4 lg:col-span-4 xl:col-span-3 lg:border-white lg:border-t-4 lg:border-l-4">
          <BackgroundImage {...rental2} className="h-full">
            &nbsp;
          </BackgroundImage>
        </div>
      </div>
    )
  }

  return (
    <>
      <ContentContainer
        bgImage={bgImage}
        className="flex lg:hidden flex-row bg-blend-darken bg-black bg-opacity-60 "
      >
        <InstructorContent />
      </ContentContainer>
      <ContentContainer
        bgImage={bgImage}
        className="hidden lg:flex flex-row bg-blend-darken bg-black bg-opacity-30 "
      >
        <InstructorContent />
      </ContentContainer>
    </>
  )
}

export default Instructors
