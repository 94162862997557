import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const MenuImage = ({ index, data, portrait, previousPortrait }) => {
  const menu = useStaticQuery(graphql`
    {
      arrow: file(relativePath: { eq: "menu-arrow.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  let display = ""
  let arrowLocation = "absolute right-5 top-20"
  let priceLocation = ""
  let direction = ""
  let arrowStyle = ""
  let image = ""

  switch (index) {
    case 0: {
      direction = "flex flex-row w-full"
      display = "w-full flex flex-row"
      image = "relative w-1/2"
      arrowLocation = "w-1/2 flex flex-row relative right-10 top-4"
      arrowStyle = "w-1/2"
      priceLocation = "relative -right-3 top-3"
      break
    }
    case 1: {
      direction = "flex flex-row-reverse w-full"
      display = "w-1/2 flex flex-col relative -top-8 md:-top-14 lg:-top-20 lg:right-16 right-10"
      image = "relative w-full"
      arrowLocation = "w-full flex flex-col absolute -bottom-16 md:-bottom-20 xl:-bottom-24 -right-16"
      arrowStyle = "w-1/2 transform rotate-60"
      priceLocation = "relative top-4 -right-10"
      break
    }
    case 2: {
      direction = "flex flex-row w-full"
      display =
        "w-1/2 flex flex-row relative -top-28 md:-top-40 lg:-top-48 left-4"
      image = "relative w-2/3"
      arrowLocation = "w-full flex flex-col absolute -bottom-20 md:-bottom-28 left-8"
      arrowStyle = "w-1/2 transform rotate-90"
      priceLocation = "relative right-0 top-8"
      break
    }
    default: {
      direction = "flex flex-row-reverse w-full"
      display = "w-1/2 flex flex-col relative -top-36 md:-top-56 lg:-top-72 right-10"
      image = "relative w-full"
      arrowLocation = "w-full flex flex-col absolute -bottom-20 md:-bottom-24 lg:-bottom-28 xl:-bottom-36 -right-16"
      arrowStyle = "w-1/2 transform scale-x-flip -rotate-45 "
      priceLocation = "relative top-3 right-14"
      break
    }
  }
  return (
    <div className={direction}>
      <div className={display}>
        <div className={image}>
          <GatsbyImage alt={`Menu${index}`} image={data.image.data} />
        </div>
        <div className={arrowLocation}>
          <div className={arrowStyle}>            
            <GatsbyImage alt="" image={getImage(menu.arrow)} />
          </div>
          <div className={priceLocation}>
            {/* <div className={`flex flex-col text-orange1 ${priceLocation}`}> */}
            <p className="font-nanum text-xl md:text-2xl lg:text-4xl xl:text-5xl max-w-menu xl:break-words">
              {data.description}
            </p>
            <p className="font-montserrat text-xs">{data.price}</p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
    // <div className={direction}>
    //   <div className={display}>
    //     <div className={image}>
    //       <GatsbyImage alt={`Menu${index}`} image={data.image.data} />
    //       <div className={arrowLocation}>
    //         <div className={arrowStyle}>
    //           <GatsbyImage alt="" image={getImage(menu.arrow)} />
    //         </div>
    //         <div className={priceLocation}>
    //           <div className={`flex flex-col text-orange1 ${priceLocation}`}>
    //             <p className="font-nanum text-xl md:text-2xl lg:text-4xl xl:text-5xl max-w-menu xl:break-words">
    //               {data.description}
    //             </p>
    //             <p className="font-montserrat text-xs xl">{data.price}</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div className={direction}>
    // <div className={display}>
    //   <div className={image}>a</div>
    //   <div className="bg-red-200">b</div>
    //   <div className="bg-blue-200">c</div>
    // <div className={image}>
    //   <GatsbyImage alt={`Menu${index}`} image={data.image.data} />
    // </div>
    // <div className="w-full bg-yellow-200">
    //   <div className={arrowLocation}>
    //     <GatsbyImage alt="" image={getImage(menu.arrow)} />
    //   </div>
    //   <div className={`flex flex-col text-orange1 ${priceLocation}`}>
    //     <p className="font-nanum text-xl md:text-2xl lg:text-4xl xl:text-5xl max-w-menu xl:break-words">
    //       {data.description}
    //     </p>
    //     <p className="font-montserrat text-xs xl">{data.price}</p>
    //   </div>
    // </div>
    // </div>
    // </div>
  )
}

export default MenuImage
