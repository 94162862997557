import React from "react"
import Content from "../../Content"
import ContentContainer from "../../ContentContainer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"

const BeingPresent = () => {
  const beingPresent = useStaticQuery(graphql`
    {
      file1: file(relativePath: { eq: "being-present-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      file2: file(relativePath: { eq: "being-present-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      file3: file(relativePath: { eq: "being-present-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      file4: file(relativePath: { eq: "being-present-4.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bg: file(relativePath: { eq: "bg-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image1 = getImage(beingPresent.file1)
  const image2 = getImage(beingPresent.file2)
  const image3 = getImage(beingPresent.file3)
  const image4 = getImage(beingPresent.file4)
  const bgImage = convertToBgImage(getImage(beingPresent.bg))

  return (
    <ContentContainer bgImage={bgImage}>
      <Content className="flex flex-col-reverse sm:flex-col xl:flex-row py-14 px-10 xl:px-0">
        <div className="w-full xl:w-1/2 flex justify-center xl:justify-start">
          <div className="relative -left-16 sm:-left-24 md:-left-28 xl:left-0 w-3/4 sm:w-1/2 xl:w-3/4">
            <div>
              <GatsbyImage alt="Being Present" image={image1} />
            </div>
            <div className="absolute -right-72 sm:-right-72 md:-right-80 lg:-right-40 xl:-right-20 bottom-60 sm:bottom-24 md:bottom-24 lg:bottom-0">
              <div className="relative -right-20 sm:-right-0 w-5/12 sm:w-7/12 md:w-8/12 lg:w-10/12 xl:w-full">
                <GatsbyImage alt="Being Present" image={image2} />
                <div className="absolute w-full md:right-0 lg:-right-40 xl:-right-52 -bottom-64 sm:-bottom-28 md:-bottom-32 lg:bottom-0">
                  <div>
                    <div className="hidden sm:inline-block">
                      <GatsbyImage alt="Being Present" image={image3} />
                    </div>
                    <div className="inline-block sm:hidden">
                      <GatsbyImage alt="Being Present" image={image4} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/2 text-white pb-20">
          <div className="flex flex-col gap-y-5 w-full text-left pt-10 xl:pt-0 px-0 sm:px-10 xl:px-0 xl:pl-20">
            <div>
              <div className="inline-block mt-6 uppercase">
                <h2 className="border-b-2 border-white pr-4 pb-3 sm:pb-0">
                  Unplug and disconnect
                </h2>
              </div>
            </div>
            <p className="mt-7 sm:mt-16">
              As of now, there is no phone / internet signal that can reach
              Pacifico and for some this might be a problem. Coming up here and
              being able to unplug and disconnect, even for a few days, from the
              fast-paced world of social media, emails, answering calls & all
              the other things we do online is a great way to recharge, rest,
              and be inspired.
            </p>
          </div>
        </div>
      </Content>
      {/* <Content className="flex flex-col 2xl:flex-row mt-56 mb-14 2xl:mb-0 text-white px-10 2xl:px-0">
        <div className="relative w-full 2xl:w-1/2 -mb-20 2xl:mb-0 flex items-center justify-center">
          <div className="flex flex-row">
            <div className="relative -top-40 -left-4 2xl:left-0 w-10/12 2xl:w-full">
              <GatsbyImage alt="Being Present" image={image1} />
            </div>
            <div className="absolute left-44 2xl:right-12 bottom-28 2xl:bottom-20 w-7/12 2xl:w-full flex justify-end 2xl:justify-center">
              <GatsbyImage alt="Being Present" image={image2} />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 w-full 2xl:w-1/2 2xl:pl-28 text-left">
          <h2 className="mt-6">Unplug and disconnect</h2>
          <hr className="w-7/12 mt-1 border-t-2 border-white" />
          <p className="mt-14">
            As of now, there is no phone / internet signal that can reach
            Pacifico and for some this might be a problem. We, on the contrary,
            believe that coming up here and being able to unplug and disconnect,
            even for a few days, from the fast-paced world of social media,
            emails, answering calls & all the other things we do online is a
            great way to recharge, rest, and be inspired.
          </p>
        </div>
      </Content> */}
    </ContentContainer>
  )
}

export default BeingPresent
